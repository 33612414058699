import { EdbCategoryDayMode as ApiEdbCategoryDayMode, EdbCurrencyID as ApiEdbCurrencyID } from './api'
import type { SortRuleEnum, GetProductsReqParamsWithTags } from './product'

export interface GetFiltersReqParams {
  region_id: number
  category_id?: string
  sub_category_id?: string
  currency_id?: string
  show_category_filter?: boolean
}

export enum FilterTypeEnum {
  AGE = 'age',
  PRICE = 'price',
  DATE = 'date',
  ORDER = 'order',
}

export interface FilterComponentProps extends Partial<GetProductsReqParamsWithTags> {}

export interface FilterComponentState {
  [FilterTypeEnum.ORDER]: SortRuleEnum
  [FilterTypeEnum.AGE]: number[]
  [FilterTypeEnum.DATE]: [Nullable<number>, Nullable<number>]
  [FilterTypeEnum.PRICE]: [Nullable<number>, Nullable<number>]
  [key: string]: unknown
}

export enum FilterUiTypeEnum {
  CHECK_BOX = 'checkbox',
  RADIO = 'radio',
  DATE_RANGE = 'date-range',
  RANGE = 'range',
  SELECT = 'select',
  // DATE = 'date',
}

export const DayModeCountMap: Record<ApiEdbCategoryDayMode, number> = {
  [ApiEdbCategoryDayMode.CategoryDayModeDefault]: 7,
  [ApiEdbCategoryDayMode.CategoryDayModeCalendar]: 30,
  [ApiEdbCategoryDayMode.CategoryDayModeCalendar180Days]: 180,
  [ApiEdbCategoryDayMode.CategoryDayModeCalendar60Days]: 60,
  [ApiEdbCategoryDayMode.CategoryDayModeCalendar90Days]: 90,
  [ApiEdbCategoryDayMode.CategoryDayModeCalendar365Days]: 365,
}

// 幣別ID 0: 台幣, 1: 港幣, 2: 日幣, 3: 馬幣, 4: 泰銖
export enum CurrencyEnum {
  TWD = ApiEdbCurrencyID.CurrencyIDTWD,
  HKD = ApiEdbCurrencyID.CurrencyIDHKD,
  JPY = ApiEdbCurrencyID.CurrencyIDJPY,
  MYR = ApiEdbCurrencyID.CurrencyIDMYR,
  THB = ApiEdbCurrencyID.CurrencyIDTHB,
}

/**
 * filter tag 之後會在各個環境同步
 * @reference: https://zoeknow.atlassian.net/browse/ZOEKIT-11423
 */
export enum FilterTagIdEnum {
  AGE = 223,
}
export interface QueryStringFilterState {
  age?: number[]
  start_date?: string
  end_date?: string
  min_price?: number
  max_price?: number
  [key: string]: unknown
}
export enum ModeQueryStringEnum {
  POPULAR_LIST_KEY = 'popular',
  NEWEST_LIST_KEY = 'new',
}
